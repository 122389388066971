
export default {
    name: 'promoCode',
    layout: 'main-head',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: false,
            skeleton: true,
            bs: [],
            gold: [],
            sc: [],
            cus: {
                url: {}
            },
            coupon: {
                visible: false,
                hasToast: false,
                url: '',
                type: ''
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        !this.hasLogin && this.steInitialCouponPopup()
        if(document.referrer.includes('/login') || this.hasLogin) {
            this.getCouponAjax()
        }
    },
    methods: {
        // 领取优惠券接口
        getCouponAjax() {
            this.$api.coupon.getQrCodeCoupon().then(response => {
                const res = response.result || {};
                this.skeleton = false
                if(!res.status) {
                    this.coupon.hasToast && this.$Toast(res.message)
                    return
                }
                this.setCouponStatus(res)
            })
        },
        // 弹窗设置
        setCouponStatus(obj) {
            this.coupon.type = 2
            this.coupon.url = `${obj.popup}?x-oss-process=image/resize,w_750`
            let clear= setTimeout(() => {
                this.coupon.visible = true
                clearTimeout(clear)
            }, 100)
        },
        steInitialCouponPopup() {
            this.$nextTick(() => {
                let clear = setTimeout(() => {
                    this.coupon.visible = true
                    this.coupon.type = 1
                    this.coupon.url = 'https://cdn2.selleroa.com/yfn-upload/review/1731312847981.jpg'
                    this.skeleton = false
                    clearTimeout(clear)
                }, 3000)
            })
        },
        // 领取优惠券
        handleCoupon() {
            if(!this.hasLogin) {
                this.$router.push({
                    name: 'Login'
                })
                return
            }
            this.coupon.hasToast = true
            this.getCouponAjax()
        },
        // 跳转
        jumpCoupon() {
            this.coupon.visible = false
            this.$router.push({
                name: 'Coupon'
            })
        },
        jumpIndex() {
            this.coupon.visible = false
            this.$router.push({
                name: 'Home'
            })
        },
        jumpLink(obj, bool) {
            if(bool) {
                window.location.href = obj.seoUrl
                return
            }
            if(obj.name == 'Product-Seo') {
                this.$router.push({
                    name: obj.name,
                    params: {
                        code: obj.code,
                        seoUrl: obj.seoUrl
                    }
                })
            } else [
                this.$router.push(obj.seoUrl)
            ]
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Get your exclusive discount now!'),
                keywords: this.$translate('Never miss it'),
                description: this.$translate('Never miss it'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
            this.bs = [{
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313014865.png',
                name: 'Product-Seo',
                code: '90576934',
                seoUrl: 'sterling-silver-moonstone-tree-of-life-pendant-necklace-p90576934'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313017314.png',
                name: 'Product-Seo',
                code: '12937880',
                seoUrl: '14k-white-gold-heart-shaped-crystal-heart-with-heart-pendant-necklace-with-engraved-word-p12937880'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313019261.png',
                name: 'Product-Seo',
                code: '24667596',
                seoUrl: 'sterling-silver-two-tone-cat-moon-pendant-necklace-p24667596'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313022187.jpg',
                name: 'Product-Seo',
                code: '13628009',
                seoUrl: 'sterling-silver-round-moonstone-mushroom-moon-pendant-necklace-p13628009'
            }]
            this.gold = [{
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313136527.png',
                name: 'Product-Seo',
                code: '87176464',
                seoUrl: '14k-gold-cubic-zirconia-parents-children-heart-pendant-necklace-p87176464'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313138792.png',
                name: 'Product-Seo',
                code: '43949152',
                seoUrl: '14k-gold-cubic-zirconia-cat-heart-pendant-necklace-p43949152'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313140635.png',
                name: 'Product-Seo',
                code: '60606426',
                seoUrl: '14k-gold-opal-dolphin-heart-pendant-necklace-p60606426'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313143204.jpg',
                name: 'Product-Seo',
                code: '98760554',
                seoUrl: '14k-gold-cubic-zirconia-sunflower-pendant-necklace-p98760554'
            }]
            this.sc = [[{
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313444348.jpg',
                name: 'CategoryDetail-Seo',
                code: '',
                seoUrl: 'collection/cremation-urn-necklaces-for-ashes'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313447360.jpg',
                name: 'Search',
                code: '',
                seoUrl: 'search?keyword=Saint+Michael'
            }], [{
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313451541.jpg',
                name: 'CategoryDetail-Seo',
                code: '',
                seoUrl: 'collection/crystal-necklaces'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313455738.jpg',
                name: 'CategoryDetail-Seo',
                code: '',
                seoUrl: 'collection/engagement-wedding-rings'
            }], [{
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313458959.jpg',
                name: 'CategoryDetail-Seo',
                code: '',
                seoUrl: 'collection/flower-earrings'
            }, {
                src: 'https://cdn2.selleroa.com/yfn-upload/review/1731313516811.jpg',
                name: 'CategoryDetail-Seo',
                code: '',
                seoUrl: 'collection/body-jewelry'
            }]]
            this.cus.url = {
                name: 'CategoryDetail-Seo',
                code: '',
                seoUrl: 'collection/personalized-jewelry-custom-jewelry'
            }
        }
    }
}
